import React from 'react';
import {IClassNameProps} from '@bem-react/core';

export interface IOrderListProps extends IClassNameProps {

}
export const OrderList:React.FC<IOrderListProps> = () => {
    return (
        <div className={'OrderList'}>OrderList</div>
    );
}