import {defaultLightTheme} from 'react-admin';
import {RaThemeOptions} from 'ra-ui-materialui/src/theme/types';

export const defaultTheme: RaThemeOptions = {
    ...defaultLightTheme,
    palette: {
        background: {
            default: '#fafafb',
        },
        primary: {
            main: "#204FC8"
        },
        secondary: {
            light: '#FCDEDE',
            main: '#F25B5B',
            dark: '#c44040',
            contrastText: '#fff',
        },
    },
    typography: {
        // Use the system font instead of the default Roboto font.
        fontFamily: ['Inter', 'sans-serif'].join(','),
    },
    components: {
        ...defaultLightTheme.components,
        RaLogin: {
            avatar: {
                display: 'none'
            }
        },
        RaLocalesMenuButton: {
            styleOverrides: {
                root: {
                    '& .MuiButton-startIcon': {
                        display: 'none'
                    }
                },

            }
        }
    },
};