import React, {useEffect, useState} from 'react';
import './App.css';
import {IClassNameProps} from '@bem-react/core';
import {Admin, Resource, useStore} from 'react-admin';
import {ProjectList} from '../resources/ProjectList';
import {OrderList} from '../resources/OrderList';
import {ThreeCatalogList} from '../resources/ThreeCatalogList';
import {dataProvider} from '../providers/dataProvider';
import {authProvider} from '../providers/authProvider';
import {i18nProvider} from '../providers/i18nProvider';
import {defaultTheme} from '../themes/default';
import {Dashboard} from '../dashboard/Dashboard';
import {AppLayout} from '../layout/AppLayout';
import {AppLoginPage} from '../layout/AppLoginPage';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import {IClientSessionData} from '../../common-code/interfaces/session/IClientSessionData';
import {IAppConfig} from '../../common-code/interfaces/config/IAppConfig';
import {useEffectDidMount} from '../hooks/useEffectDidMount';
import {TAppUrlOptions} from '../../common-code/types/appConfig/TAppUrlOptions';
import axios from 'axios';
import {IAppInitConfigData} from '../../../../common/interfaces/config/IAppInitConfigData';
import useUrlConfigId from '../hooks/useUrlConfigId';
import {cn} from '@bem-react/classname';
import {useTranslation} from 'react-i18next';
import {ProjectTemplateList} from '../resources/ProjectTemplateList';

export const cnAdminApp = cn('AdminApp');

interface IAppProps extends IClassNameProps {
    urlOptions: TAppUrlOptions;
}


export const App: React.FC<IAppProps> = ({urlOptions}) => {
    const [session, setSession] = useStore<IClientSessionData | null>('auth.session', null);
    const [config, setConfig] = useStore<IAppConfig | null>('config', null);
    const [sessionError, setSessionError] = useState<boolean>(false);
    const [configError, setConfigError] = useState<boolean>(false);
    const configId: string = useUrlConfigId();
    const {t} = useTranslation();

    axios.defaults.withCredentials = true;

    const getParentHost = (): string => {
        let parentPath: string | undefined;
        let parentUrl: URL;

        parentPath = (window.location !== window.parent.location)
            ? document.referrer
            : document.location.href;
        if (parentPath) {
            parentUrl = new URL(parentPath)

            return parentUrl.host.split(':')[0];
        }

        return '';
    }

    const startSession = (appUrlOptions?: TAppUrlOptions): Promise<boolean> => {
        return axios.post('/api/auth/session-start', appUrlOptions)
            .then((response: any) => {
                if (response.data && response.data.user && response.data.seanceId) {
                    setSession(response.data);
                    return true;
                } else {
                    return false;
                }
            }).catch(() => {
                return false;
            });
    }

    const loadConfig = (configId: string): Promise<boolean> => {
        return axios.get('/api/auth/config', {
            params: {
                id: configId,
                parentHost: getParentHost(),
            }
        })
            .then((response: any) => {
                if (response.data && response.data.config) {
                    const initConfigData: IAppInitConfigData = response.data;
                    const appConfig: IAppConfig = initConfigData.config;
                    setConfig(appConfig);
                    return true;
                } else {
                    return false;
                }
            })
            .catch(() => {
                setConfigError(true);
                return false;
            });
    }

    useEffectDidMount(() => {
        startSession(urlOptions).then((isOk: boolean) => {
            if (isOk && (!config || (config && config.id !== configId))) {
                loadConfig(configId).then();
            } else {
                setSessionError(true);
            }
        }).catch(() => {
            setSessionError(true);
        });
    });

    useEffect(() => {
        if (config && configId !== config.id && window && window.location) {
            window.location.href = '/' + config.id + '/';
        }
    }, [config, configId]);

    if (configError) {
        return (<div className={cnAdminApp('LoadingContainer')}>
            <div className={cnAdminApp('LoadingError')}>
                {t('Ошибка загрузки конфигурации приложения')}!
            </div>
        </div>);
    }

    if (sessionError) {
        return (<div className={cnAdminApp('LoadingContainer')}>
            <div className={cnAdminApp('LoadingError')}>
                {t('Ошибка загрузки приложения')}!
            </div>
        </div>);
    }

    if (!session || !config) {
        return (<div className={cnAdminApp('LoadingContainer')}>
            <div className={cnAdminApp('Loading')}>
                {t('Загружаем')}...
            </div>
        </div>);
    }

    return (
        <Admin basename={configId}
               requireAuth={true}
               dataProvider={dataProvider}
               authProvider={authProvider}
               i18nProvider={i18nProvider}
               loginPage={AppLoginPage}
               dashboard={Dashboard}
               theme={defaultTheme}
               layout={AppLayout}
        >
            <Resource name="projects"
                      list={ProjectList}
                      icon={ListAltIcon}
            />
            <Resource name="project-templates"
                      list={ProjectTemplateList}
                      icon={ListAltIcon}
            />
            <Resource name="orders"
                      list={OrderList}
                      icon={ShoppingCartIcon}
            />
            <Resource name="3d"
                      list={ThreeCatalogList}
                      icon={ViewInArIcon}

            />
        </Admin>
    );
}
