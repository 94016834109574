import {I18nProvider} from 'react-admin';
import {i18n} from '../i18n';
import {DEFAULT_LANGUAGE} from '../../common-code/constants';

export const i18nProvider: I18nProvider = {
    translate: (key) => {
        if (!i18n.exists(key)) {
            console.log(key);
        }
        return i18n.t(key, {

        });
    },
    changeLocale: (locale): Promise<void> => {
        i18n.changeLanguage(locale);
        return Promise.resolve();
    },
    getLocale: () => {
        return i18n.resolvedLanguage || DEFAULT_LANGUAGE;
    },
    getLocales: () => i18n.options.resources ? Object.keys(i18n.options.resources).map(value => {
        return {locale: value, name: value};
    }) : [],
    availableLocales: i18n.options.resources ? Object.keys(i18n.options.resources).map(value => {
        return {locale: value, name: value};
    }) : []

}