import React, {useState} from 'react';
import {IClassNameProps} from '@bem-react/core';
import {useEffectDidMount} from '../hooks/useEffectDidMount';
import axios from 'axios';
import {useStore} from 'react-admin';
import {IAppConfig} from '../../../../common/interfaces/config/IAppConfig';
import {IImportOffer} from '../../../../common/interfaces/import/IImportOffer';
import {CatalogStatistic} from '../components/CatalogStatistic/CatalogStatistic';

export interface IThreeCatalogListProps extends IClassNameProps {

}
export const ThreeCatalogList:React.FC<IThreeCatalogListProps> = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [config] = useStore<IAppConfig>('config');
    const [unusedModules, setUnusedModules] = useState<IImportOffer[]>([]);
    useEffectDidMount(() => {
        setLoading(true);
        axios.get('/api/catalog/unused-modules', {params: {config: config?.id}}).then((response) => {
            if (response.data && response.data.length) {
                setUnusedModules(response.data)
            } else {
                setUnusedModules([])
            }
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        })
    });
    return (
        <div className={'ThreeCatalogList'}>
            ThreeCatalogList {loading}
            {unusedModules.map(module => {
                return (<div className={'ThreeCatalogList-Item'} key={module.id}>
                    <div className={''}>
                        {module.id}
                    </div>
                    <div className={''}>
                        {module.name}
                    </div>
                </div> );
            })}
            <CatalogStatistic />
        </div>
    );
}