import {IClassNameProps} from '@bem-react/core';
import React, {useState} from 'react';
import {useSidebarState} from 'react-admin';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {List, ListItemText, Collapse, ListItemButton} from '@mui/material';

export interface IAppSubMenuProps extends IClassNameProps {
    children?: React.ReactNode;
    isDropdownOpen?: boolean;
    leftIcon: React.ReactElement;
    primaryText: string;
}

export const AppSubMenu:React.FC<IAppSubMenuProps> = (props) => {
    const {
        isDropdownOpen = false,
        primaryText,
        leftIcon,
        children
    } = props;
    const [open] = useSidebarState();
    const [isOpen, setIsOpen] = useState(isDropdownOpen);
    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <React.Fragment>
            <ListItemButton
                aria-label={primaryText}
                dense
                onClick={handleToggle}
                sx={{
                    paddingLeft: '1rem',
                    color: 'rgba(0, 0, 0, 0.54)',
                }}
            >
                {leftIcon}
                <ListItemText
                    inset
                    disableTypography
                    primary={primaryText}
                    sx={{
                        paddingLeft: 2,
                        fontSize: '1rem',
                        color: 'rgba(0, 0, 0, 0.6)',
                    }}
                />
                {isOpen ? <ExpandMoreIcon /> : <KeyboardArrowRightIcon/>}
            </ListItemButton>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <List
                    component="div"
                    disablePadding
                    sx={open ? {
                        paddingLeft: '25px',
                        transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
                    } : {
                        paddingLeft: 0,
                        transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
                    }}
                >
                    {children}
                </List>
            </Collapse>
        </React.Fragment>
    )
}