import React from 'react';
import {Card, CardContent, CardHeader} from '@mui/material';
import {useTranslation} from 'react-i18next';

export const Dashboard:React.FC = () => {
    const {t} = useTranslation();

    return (
        <Card>
            <CardHeader title={t('Добро пожаловать в личный кабинет 3Dplan')} />
            <CardContent></CardContent>
        </Card>
    );
}