import {IClassNameProps} from '@bem-react/core';
import {useStore} from 'react-admin';
import {IAppConfig} from '../../../../../common/interfaces/config/IAppConfig';

export interface ICatalogStatisticProps extends IClassNameProps {

}
export const CatalogStatistic: React.FunctionComponent<ICatalogStatisticProps> = props => {
    const [config] = useStore<IAppConfig>('config');

    return (
        <div className={'CatalogStatistic'}>
            {config.id} {props.className}
        </div>
    );
}