import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {App} from './app/main/App';
import {TAppUrlOptions} from '../../common/types/appConfig/TAppUrlOptions';
import SvgSprite from './app/SvgSprite/SvgSprite';
import {getUrlConfigId} from './app/helpers';
import './index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const getAppUrlOptions = (): TAppUrlOptions => {
    let urlOptions: TAppUrlOptions;

    urlOptions = {};

    if (window && window.location) {
        urlOptions.configId = getUrlConfigId();
    }

    return urlOptions;
}

root.render(
  <React.StrictMode>
      <SvgSprite/>
    <App urlOptions={getAppUrlOptions()}/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
