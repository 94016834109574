import React from 'react';
import {IClassNameProps} from '@bem-react/core';

export interface IProjectListProps extends IClassNameProps {

}
export const ProjectList:React.FC<IProjectListProps> = () => {
    return (
        <div className={'ProjectList'}>ProjectList</div>
    );
}