import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {resources} from './languages';
import {initReactI18next} from 'react-i18next';
import {DEFAULT_LANGUAGE} from '../../common-code/constants';

document.documentElement.lang = DEFAULT_LANGUAGE;

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: false,
        lng: DEFAULT_LANGUAGE,
        resources,
    });

export {i18n};