import React from 'react';
import {Login, LoginForm} from 'react-admin';
import './AppLoginPage.css';
import {cn} from '@bem-react/classname';
import {useTranslation} from 'react-i18next';
export const cnAppLoginPage = cn('AppLoginPage');

export const AppLoginPage:React.FC = () => {
    const {t} = useTranslation();
    return (
        <Login className={cnAppLoginPage()}>
            <div className={cnAppLoginPage('Title')}>
                {t('3Dplan личный кабинет')}
            </div>
            <LoginForm/>
        </Login>
    );
}