import {AuthProvider} from 'react-admin';
import axios from 'axios';
import {IClientUser} from '../../common-code/interfaces/user/IClientUser';
import {getUrlConfigId} from '../helpers';

export const authProvider: AuthProvider = {
    login: ({ username, password }) => {
        return axios.post('/auth/login', {
            username: username,
            password: password,
        }, {params: {config: getUrlConfigId()}}).then((response) => {
            if (response.data && response.data.user &&
                response.data.user.id && response.data.seanceId) {
                localStorage.setItem('auth', JSON.stringify(response.data.user));
                return Promise.resolve();
            } else {
                return Promise.reject();
            }
        }).catch(() => {
            return Promise.reject();
        })
    },
    logout: () => {
        return axios.post('/auth/logout', {}, {params: {config: getUrlConfigId()}})
            .then(() => {
                localStorage.removeItem('auth');
                return Promise.resolve();
            })
            .catch(() => {
                return Promise.reject();
            });
    },
    getIdentity: () => {
        let authDataStr: string | null =  localStorage.getItem('auth');
        let authData: IClientUser | undefined;

        if (authDataStr) {
            authData = JSON.parse(authDataStr);
        }
        if (authData) {
            return Promise.resolve({id: authData.id, fullName: authData.firstName})
        } else {
            return Promise.reject();
        }
    },
    checkAuth: () => {
        return localStorage.getItem('auth') ? Promise.resolve() : Promise.reject()
    },
    checkError:  (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('auth');
            return Promise.reject();
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    getPermissions: () => Promise.resolve(''),
};