import {Menu} from 'react-admin';
import React from 'react';
import {AppSubMenu} from './AppSubMenu';
import {useTranslation} from 'react-i18next';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import CreditScoreIcon from '@mui/icons-material/CreditScore';

export const AppMenu:React.FC = () => {
    const {t} = useTranslation();
    return (
        <Menu>
            <Menu.DashboardItem />
            <AppSubMenu primaryText={t('Проекты')} leftIcon={<AccountTreeIcon />}>
                <Menu.ResourceItem name="projects" />
                <Menu.ResourceItem name="project-templates" />
                <Menu.ResourceItem name="orders" />
            </AppSubMenu>
            <AppSubMenu primaryText={t('Каталоги')} leftIcon={<AccountTreeIcon />}>
                <Menu.ResourceItem name="3d" />
                <Menu.ResourceItem name="catalog" />
            </AppSubMenu>

            <AppSubMenu primaryText={t('Дилерская сеть')} leftIcon={<DeviceHubIcon />}>
            </AppSubMenu>
            <AppSubMenu primaryText={t('Оплата')} leftIcon={<CreditScoreIcon />}>

            </AppSubMenu>
        </Menu>

    );
}