import React from 'react';
import {IClassNameProps} from '@bem-react/core';

export interface IProjectTemplateListProps extends IClassNameProps {

}
export const ProjectTemplateList:React.FC<IProjectTemplateListProps> = () => {
    return (
        <div className={'ProjectTemplateList'}>ProjectTemplateList</div>
    );
}