import React from 'react';
import {Layout} from 'react-admin';
import {AppBar} from './AppBar';
import {AppMenu} from './AppMenu';

export const AppLayout:React.FC = (props) => {
    return (
        <Layout
            {...props}
            appBar={AppBar}
            menu={AppMenu}
        />
    );
}